import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CKEditor from '@ckeditor/ckeditor5-vue';

import { setApiUrl } from "@/config";
setApiUrl();

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

library.add(fas);

const app = createApp(App);
app.use(store).use(router).use(Toast).use(CKEditor).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
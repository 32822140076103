<template>
    <router-view v-slot="{ Component }">
        <transition name="main">
            <component :is="Component" />
        </transition>
    </router-view>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions(['tryToken', 'initIcons'])
    },
    mounted: async function () {
        if (await this.tryToken())
            this.$router.push({ name: "Projekte" });
        else
            this.$router.push({ name: "Login" });

        await this.initIcons();
    }
};
</script>

<style lang="scss">
#app {
    font-family: "Source Sans Pro";
}

.main-enter-active,
.main-leave-active {
    transition: opacity 0.5s;
}
.main-enter,
.main-leave-to {
    opacity: 0;
}
</style>

import { createStore } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
import Cookies from "js-cookie";
import { getAssetsUrl } from "@/config";

const toast = useToast();

export default createStore({
    state: {
        projects: [],
        projectsLoaded: false,
        loggedIn: false,
        token: Cookies.get("token") || undefined,
        icons: {},
    },
    getters: {
        projects: (state) => state.projects,
        projectsLoaded: (state) => state.projectsLoaded,
        loggedIn: (state) => state.loggedIn,
        token: (state) => state.token,
        icons: (state) => state.icons,
    },
    mutations: {
        setLoggedIn: (state, value) => {
            state.loggedIn = value;
        },
        setToken: (state, value) => {
            state.token = value;
        },
        updateProjects: (state, projects) => {
            state.projects = projects;
            state.projectsLoaded = true;
        },
        updateSurveys: (state, { projectID, surveys }) => {
            var project = state.projects.find((x) => x.id == projectID);

            project.surveys = surveys;
        },
        updatePages: (state, { projectID, surveyID, pages }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);

            survey.pages = pages;
        },
        updateElements: (state, { projectID, surveyID, pageID, elements }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var page = survey.pages.find((x) => x.id == pageID);

            page.elements = elements;
        },
        addProject: (state, project) => {
            state.projects.push(project);
        },
        deleteProject: (state, projectID) => {
            state.projects.splice(
                state.projects.findIndex((x) => x.id == projectID),
                1
            );
        },
        editProject: (state, { name, projectID }) =>
            (state.projects.find((x) => x.id == projectID).name = name),
        addSurvey: (state, { projectID, survey }) =>
            state.projects.find((x) => x.id == projectID).surveys.push(survey),
        deleteSurvey: (state, { projectID, surveyID }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var surveyIndex = project.surveys.findIndex((x) => x.id == surveyID);

            project.surveys.splice(surveyIndex, 1);
        },
        editSurvey: (
            state,
            { projectID, surveyID, name, informationType, phonenumber }
        ) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);

            survey.name = name;
            survey.informationType = informationType;
            survey.phonenumber = phonenumber;
        },
        addPage: (state, { projectID, surveyID, page }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);

            survey.pages.push(page);
        },
        deletePage: (state, { projectID, surveyID, pageID }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var pageIndex = survey.pages.findIndex((x) => x.id == pageID);

            survey.pages.splice(pageIndex, 1);
        },
        editPage: (
            state,
            { projectID, surveyID, pageID, title, data }
        ) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var page = survey.pages.find((x) => x.id == pageID);

            page.title = title ?? page.title;
            page.data = data ?? page.data;
        },
        initElement: (
            state,
            { projectID, surveyID, pageID, elements }
        ) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var page = survey.pages.find((x) => x.id == pageID);

            page.elements.splice(0, page.elements.length);

            elements.forEach((element) => {
                page.elements.push(element);
            });
        },
        addElement: (state, { projectID, surveyID, pageID, element }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var page = survey.pages.find((x) => x.id == pageID);

            page.elements.push(element);
        },
        deleteElement: (state, { projectID, surveyID, pageID, elementID }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var page = survey.pages.find((x) => x.id == pageID);

            var elementIndex = page.elements.findIndex((x) => x.id == elementID);
            page.elements.splice(elementIndex, 1);
        },
        deleteSelection: (state, { projectID, surveyID, pageID }) => {
            var project = state.projects.find((x) => x.id == projectID);
            var survey = project.surveys.find((x) => x.id == surveyID);
            var page = survey.pages.find((x) => x.id == pageID);

            page.elementType = 0;
            page.elements.splice(0, page.elements.length);
        },
        setIcons: (state, icons) => {
            state.icons = icons;
        }
    },
    actions: {
        async login({ commit }, password) {
            try {
                var res = await axios.post("Authenticate/login", { password });
                axios.defaults.headers.common["Authorization"] = "bearer " + res.data.token;

                commit("setLoggedIn", true);
                commit("setToken", res.data.token);
                Cookies.set("token", res.data.token);

                toast.success("Erfolgreich eingeloggt!");

                return true;
            } catch (err) {
                if (err.response && err.response.status == 401)
                    toast.error("Bitte überprüfen Sie das eingegebenen Passwort!");
                else
                    toast.error("Der API-Server ist down. Bitte versuchen Sie es später erneut!");

                return false;
            }
        },
        async tryToken({ commit, getters }) {
            if (!getters.token) return false;

            try {
                axios.defaults.headers.common["Authorization"] = "bearer " + getters.token;

                await axios.get("Project");

                commit("setLoggedIn", true);
                commit("setToken", getters.token);
                return true;
            } catch (err) {
                commit("setLoggedIn", false);
                commit("setToken", "");
                return false;
            }
        },
        async getAllProjects({ commit }) {
            try {
                var res = await axios.get("Project");
                commit("updateProjects", res.data);

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async getSurveysByProject({ commit }, projectID) {
            try {
                var res = await axios.get("Survey/GetByProjectId/" + projectID);
                commit("updateSurveys", { projectID, surveys: res.data });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async getPagesBySurvey({ commit }, { projectID, surveyID }) {
            try {
                var res = await axios.get("Page/GetBySurveyId/" + surveyID);
                commit("updatePages", { projectID, surveyID, pages: res.data });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async getElementsByPage({ commit }, { projectID, surveyID, pageID }) {
            try {
                var res = await axios.get("Element/GetByPageId/" + pageID);
                commit("updateElements", {
                    projectID,
                    surveyID,
                    pageID,
                    elements: res.data,
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async addProject({ commit }, name) {
            try {
                var res = await axios.post("Project", { name });
                commit("addProject", res.data);

                toast.success(`Neues Projekt '${name}' erstellt!`);

                return res.data;
            } catch (err) {
                toast.error(`Projekt erstellen fehlgeschlagen.`);

                return false;
            }
        },
        async deleteProject({ commit }, projectID) {
            try {
                await axios.delete("Project/" + projectID);
                commit("deleteProject", projectID);

                return true;
            } catch (err) {
                return false;
            }
        },
        async editProject({ commit }, { name, projectID }) {
            try {
                var res = await axios.put("Project", {
                    id: projectID,
                    name,
                });
                commit("editProject", { name: res.data.name, projectID });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async addSurvey({ commit }, { projectID, name, informationType, phonenumber }) {
            try {
                var res = await axios.post("Survey", {
                    name,
                    informationType,
                    phonenumber,
                    projectId: projectID,
                });
                commit("addSurvey", { projectID, survey: res.data });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async deleteSurvey({ commit }, { projectID, surveyID }) {
            try {
                await axios.delete("Survey/" + surveyID);
                commit("deleteSurvey", { projectID, surveyID });

                return true;
            } catch (err) {
                return false;
            }
        },
        async changeSurveyData(
            { commit },
            { projectID, surveyID, name, informationType, phonenumber }
        ) {
            try {
                var res = await axios.put("Survey", {
                    id: surveyID,
                    name,
                    informationType,
                    phonenumber,
                });
                commit("editSurvey", {
                    projectID,
                    surveyID,
                    name: res.data.name,
                    informationType: res.data.informationType,
                    phonenumber: res.data.phonenumber,
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async addPage({ commit }, { projectID, surveyID }) {
            try {
                var res = await axios.post("Page", { surveyId: surveyID });
                commit("addPage", { projectID, surveyID, page: res.data });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async deletePage({ commit }, { projectID, surveyID, pageID }) {
            try {
                await axios.delete("Page/" + pageID);
                commit("deletePage", { projectID, surveyID, pageID });

                return true;
            } catch (err) {
                return false;
            }
        },
        async changePage({ commit }, { projectID, surveyID, pageID, title, data }) {
            try {
                var res = await axios.put("Page", {
                    id: pageID,
                    title,
                    data,
                });

                commit("editPage", {
                    projectID,
                    surveyID,
                    pageID,
                    title,
                    data
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async initElement({ commit }, { projectID, surveyID, pageID, elementType }) {
            try {
                var res = await axios.put("Page", {
                    id: pageID,
                    elementType,
                });
                commit("initElement", {
                    projectID,
                    surveyID,
                    pageID,
                    elements: res.data.elements
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async addElement({ commit }, { projectID, surveyID, pageID }) {
            try {
                var res = await axios.post("Element", { pageId: pageID });
                commit("addElement", {
                    projectID,
                    surveyID,
                    pageID,
                    element: res.data,
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async deleteElement(
            { commit },
            { projectID, surveyID, pageID, elementID }
        ) {
            try {
                await axios.delete("Element/" + elementID);
                commit("deleteElement", { projectID, surveyID, pageID, elementID });

                return true;
            } catch (err) {
                return false;
            }
        },
        async changeElementData(_, { elementID, data, icon }) {
            try {
                var res = await axios.put("Element", {
                    id: elementID,
                    data: data ?? null,
                    icon: icon ?? null
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async deleteSelection({ commit }, { projectID, surveyID, pageID }) {
            try {
                await axios.put("Page", {
                    id: pageID,
                    elementType: 0
                });
                commit("deleteSelection", { projectID, surveyID, pageID });

                return true;
            } catch (err) {
                return false;
            }
        },
        async loadSurvey({ commit }, { projectID, surveyID }) {
            try {
                var res = await axios.get("Survey", {
                    id: surveyID,
                    eager: true
                });
                commit("setSurvey", {
                    projectID,
                    surveyID,
                    survey: res.data
                });

                return res.data;
            } catch (err) {
                return false;
            }
        },
        async initIcons({ commit }) {
            try {
                const customRequest = axios.create({
                    baseURL: getAssetsUrl(),
                });
                var res = await customRequest.get("index.json");
                console.log(res.data);
                commit("setIcons", res.data);

                return res.data;
            } catch (err) {
                return false;
            }
        }
    },
    modules: {},
});
